.project {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .project h1 {
    color: #3e497a;
    font-size: 35px;
  }

  .project img {
    width: 500px;
  }
  
  .project p {
    font-size: 40px;
    color: #3e497a;
    height: 0px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }

  .viewCode {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  @media only screen and (max-width: 600px) {
    .project img {
      width: 80vw;
      border-radius: 10px;
    }

    .project h1 {
      margin-top: 50px;
      color: #3e497a;
      font-size: 35px;
    }
  }